import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Technology } from './Technology';
import color from '../themes/colors';
import Stack from '@mui/material/Stack';
import {Link} from 'react-router-dom';

const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);

const styles = {
   
    card: {
        minWidth: 275,
        margin: '1rem 0',
        display: 'flex',
        backgroundColor: color.colors.background,
        transition: 'background-color 0.3s ease-in-out',
        boxShadow: 'none',
    },
    cardHover: {
        transition: 'background-color 0.3s ease-in-out',
        '&:hover': {
          backgroundColor: color.colors.hoverBackground,
          '& .titleHover': {
            color: color.colors.accent,},
            '& .descriptionHover': {
                color: color.colors.text,
            },
    },},
    date: {
        padding: '1rem 0 ',
        color: color.colors.secondaryText,
        minWidth: '30%',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        lineHeight: '24px',
        fontSize: '.9rem'
    },
    content: {
        width: '70%',
        textAlign: 'start'
    },
    title: {
        color: color.colors.title,
        fontSize: '1rem'
    },
    description: {
        color: color.colors.secondaryText,
        transition: 'color 0.3s ease-in-out',
        '&:hover': {
            color: color.colors.accent,
        }
    },
    stack: {
        padding: '16px 0 0',
        display:'flex',
        flexWrap: 'wrap'
      },
};

export function ExperienceCard({ fecha, puesto, empresa, descripcion, technologies, link }) {
    return (
        <Link to={link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
        <Card className='boxExperiencia' sx={{ ...styles.card, ...styles.cardHover }}>
            <Box className="descriptionHover fechaExperiencia" sx={styles.date}>{fecha}</Box>
            <CardContent className='contentExperiencia' style={styles.content}>
                <Typography className="titleHover" sx={styles.title} variant="h5" component="div">
                    {puesto}{bull}{empresa}
                </Typography>
                <Typography className='descriptionHover' sx={styles.description}>
                    {descripcion}
                </Typography>
                <Stack sx={styles.stack} direction="row" spacing={1}>
                {technologies.map((technology, index) => (
                    <Technology
                        key={index}
                        technology={technology}
                    />
                ))}
    </Stack>
               

            </CardContent>
        </Card>
        </Link>
    );
}
