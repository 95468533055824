// themes.js

const color = {
    colors: {
      title: '#e2e8f0',
      text: '#94a3b8',
      secondaryText: '#64748b',
      accent: '#5eead4',
      accentBackground: 'rgba(45,212,191,.1)',
      background: '#0f172a',
      hoverBackground: 'rgba(31, 70, 97, 0.2)'
    },
  };
  
  export default color;
  