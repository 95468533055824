import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import '../css/NavBar.css';

export const NavBar = () => {
  const menuItems = ['Acerca de', 'Experiencia', 'Proyectos'];

  const styles = {
    container: {
      margin: '3rem 0',
      paddingX: '1rem!important',
    },
    boxLinks: {
      textAlign: 'start',
      textTransform: 'uppercase'
    },
  };

  return (
    <Container className='boxNavBar' sx={styles.container}>
      <Box sx={styles.boxLinks}>
        {menuItems.map((item, index) => (
          <Typography key={index} variant="body1" component="div">
            <a className='linkDecoration' href={`#${item.replace(' ', '').toLowerCase()}`}>
              {item}
              <span className="underline"></span>
            </a>
          </Typography>
        ))}
      </Box>
    </Container>
  );
};
