import React from 'react';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';

const StyledModal = styled(Modal)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    outline: 'none',
});

const StyledVideoContainer = styled('div')({
    position: 'relative',
    width: '80vw',
    height: '80vh',
    outline: 'none',
});

const StyledIframe = styled('iframe')({
    width: '100%',
    height: '100%',
    border: 'none',
});

const VideoModal = ({ open, videoUrl, onClose }) => {
    const handleClose = () => {
        onClose();
    };

    return (
        <StyledModal
            open={open}
            onClose={handleClose}
            aria-labelledby="video-modal-title"
            aria-describedby="video-modal-description"
        >
            <StyledVideoContainer>
                <StyledIframe
                    title="YouTube Video"
                    src={videoUrl}
                    allowFullScreen
                    controls
                />
            </StyledVideoContainer>
        </StyledModal>
    );
};

export default VideoModal;
