import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Technology } from './Technology';
import color from '../themes/colors';
import Stack from '@mui/material/Stack';
import ProjectLink from './ProjectLink';
import Modal from '@mui/material/Modal';
import {Link} from 'react-router-dom';

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
    •
  </Box>
);

const styles = {
  card: {
    minWidth: 275,
    margin: '1rem 0',
    display: 'flex',
    backgroundColor: color.colors.background,
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: color.colors.hoverBackground,
      '& .titleHover': {
        color: color.colors.accent,
      },
      '& .descriptionHover': {
        color: color.colors.text,
      },
    },
    boxShadow: 'none'
  },
  imageBox: {
    padding: '1rem ',
    minWidth: '20%'
  },
  image: {
    width: '130px',
    cursor: 'pointer'
  },
  content: {
    width: '70%',
    textAlign: 'start'
  },

  title: {
    color: color.colors.title
  },
  description: {
    color: color.colors.secondaryText
  },
  stack: {
    padding: '16px 0 0',
    display: 'flex',
    flexWrap: 'wrap'

  },
  modalContent: {
    position: 'absolute',
    width: '80%',
    maxWidth: 600,
    maxHeight: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  modalImage: {
    width: '100%',
    height: 'auto'
  }
};


export function ProjectCard({ foto, github, video, nombre, descripcion, technologies, link }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
     
      <Card className='proyectCard' sx={styles.card}>
        <Box className='proyectImagen' sx={styles.imageBox}>
          <img style={styles.image} src={foto} alt="" onClick={handleOpen} />
          <ProjectLink githubUrl={github} videoUrl={video} />
        </Box>
        <CardContent className='proyectContent' style={styles.content}>
        <Link to={link} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
          <Typography className="titleHover" sx={styles.title} variant="h5" component="div">
            {nombre}
          </Typography></Link>
          <Typography className='descriptionHover' sx={styles.description}>
            {descripcion}
          </Typography>
          <Stack className='proyectStack' sx={styles.stack} direction="row" spacing={1}>
            {technologies.map((technology, index) => (
              <Technology
                key={index}
                technology={technology}
              />
            ))}
          </Stack>
        </CardContent>
      </Card>
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modalContent}>
          <img style={styles.modalImage} src={foto} alt="" />
        </Box>
      </Modal>
    </>
  );
}
