import React, { useState } from 'react';
import { ProjectCard } from './ProjectCard';
import imagenVentas from '../assets/img/projects/ventas.PNG'
import imagenGourmetGo from '../assets/img/projects/GourmetGo.png'
import imagenMitre from '../assets/img/projects/mayoristaMitre.PNG'
import imagenOryx from '../assets/img/projects/OryxHostel.PNG'
import imagenTribu from '../assets/img/projects/TribuHostel.PNG'
import imagenAlpataco from '../assets/img/projects/alpataco.PNG'

export const ProjectList = () => {
  const [isHovered, setIsHovered] = useState(false);

  const styles = {
    boxContainer: {
      padding: '2rem 0'
    },
    icon: {
      marginLeft: '5px',
      transition: 'transform 0.1s ease-in-out, margin 0.1s ease-in-out',
      width: '1rem'
    },
    iconHovered: {
      transform: 'translateY(-3px) translateX(3px)',  
      margin: '0 3px' 
    }
  };

  const projects = [
    {
      foto: imagenTribu,
      video: '',
      link: 'https://tribuhostel.com/',
      nombre: 'Tribu hostel',
      descripcion: 'Tribu hostel ofrece una experiencia tropical única con sus vibrantes ubicaciones, habitaciones temáticas y emocionantes tours. Como desarrollador, mi contribución a este proyecto fue la creación de un sitio web en WordPress que refleja la esencia tropical del hostel. Su diseño es colorido, con imágenes exóticas, el sitio invita a los visitantes a sumergirse en la atmósfera de la selva. Además de ofrecer información detallada sobre las instalaciones y servicios, implementé un sistema de contacto por WhatsApp para una comunicación fácil y rápida. Todo esto se integra perfectamente con un motor de reservas para una experiencia de reserva fluida y sin complicaciones.',
      technologies: ['WordPress', 'Divi', 'html', 'css', 'php']
    },
    {
      foto: imagenOryx,
      video: '',
      link: 'https://oryxhostel.com/',
      nombre: 'Oryx hostel',
      descripcion: 'Oryx Hostel es una experiencia de viaje integral con múltiples ubicaciones, variedad de habitaciones, tours emocionantes y servicios útiles. Mi contribución como desarrollador fue crear un sitio web multiidioma en WordPress, con galería de imágenes, blog, información detallada del establecimiento y un práctico sistema de contacto por WhatsApp, todo vinculado a un motor de reservas para una experiencia de reserva sin problemas.',
      technologies: ['WordPress', 'Divi', 'html', 'css', 'php']
    },
    {
      foto: imagenAlpataco,
      video: '',
      link: 'https://www.alpataco.com.ar/',
      nombre: 'Alpataco',
      descripcion: 'Alpataco ofrece una experiencia de compra enológica y artesanal en su e-commerce de WordPress. Desde vinos selectos hasta artículos de cuero y accesorios, los clientes pueden explorar y comprar con facilidad. Mi aporte como desarrollador incluyó la creación de un sitio completo con carrito de compras, opciones de pago variadas y contacto por WhatsApp para asistencia rápida.',
      technologies: ['WordPress', 'Divi', 'html', 'css', 'php']
    },
    {
      foto: imagenGourmetGo,
      github: 'https://github.com/barreraleonardo/Certified-Tech-Developer-proyecto-integrador',
      video: 'https://www.youtube.com/embed/stthU1etw_I?si=0yVjcycZTmg4NAyV',
      link: '',
      nombre: 'GourmetGo',
      descripcion: 'GourmetGo es un booking de reservas de catering. Fue el proyecto integrador de la carrera Certified Tech Developer',
      technologies: ['react', 'css', 'html', 'java', 'AWS']
    },
    {
      foto: imagenMitre,
      video: 'https://www.youtube.com/embed/reNfrY_lEVg?si=b4aGMe-6-VtQGMyc',
      link: 'https://mayoristamitre.com.ar',
      nombre: 'Mayorista Mitre',
      descripcion: 'Mayorista Mitre es un sitio web, desarrollado en WordPress, permite a los usuarios explorar fácilmente todo lo que la distribuidora tiene para ofrecer. Desde ofertas exclusivas y la ubicación de nuestras sucursales hasta un formulario de contacto, Mayorista Mitre está diseñado para brindar una experiencia completa y accesible. Una de las características más destacables es la vinculación directa con publicaciones de Instagram, manteniendo a sus clientes siempre informados sobre las últimas novedades y promociones en tiempo real.',
      technologies: ['WordPress', 'Divi', 'html', 'css', 'php']
    },
    {
      foto: imagenVentas,
      github: 'https://github.com/barreraleonardo/sistema-ventas',
      video: '',
      link: '',
      nombre: 'Sistema de ventas',
      descripcion: 'Sistema de Gestión de Ventas: Registra gastos y ventas, calcula ganancias, y ofrece filtros por día, semana, mes y año. Ideal para control detallado',
      technologies: ['react', 'html', 'css', 'Materia UI', 'java', 'springboot', 'MySQL']
    }
  ];

  if (window.innerWidth <= 992) {
    styles.boxContainer.padding = '0';
  }
  return (
    <div style={styles.boxContainer}>
       {projects.map((project, index) => (
        <ProjectCard
          key={index}
          foto={project.foto}
          github={project.github}
          video={project.video}
          link={project.link}
          nombre={project.nombre}
          descripcion={project.descripcion}
          technologies={project.technologies}
        />
      ))}
    </div>
  );
};
