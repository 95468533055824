import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import color from '../themes/colors'

export  function Technology({ technology }) {

    const styles = {
        stack: {
          padding: '16px 0 0',
        },
        chip: {
          color: color.colors.accent,
          backgroundColor: color.colors.accentBackground
        },
      };

  return (
    <Stack sx={styles.stack} direction="row" spacing={1}>
      <Chip sx={styles.chip} label={technology}/>
    </Stack>
  );
}