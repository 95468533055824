import './App.css';
import { Info } from './components/Info';
import { Main } from './components/Main';



function App() {
  return (
    <div className="App" style={{ maxWidth: '1300px', margin: '0 auto' }}>
      <header className="App-header">
        <Info></Info>
      </header>
      <Main></Main>
    </div>
  );
}

export default App;
