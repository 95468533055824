import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import color from '../themes/colors';
import GitHubIcon from '@mui/icons-material/GitHub';
import VideoModal from './VideoModal';

const ProyectLink = ({ githubUrl, videoUrl }) => {
    const [hoveredIcon, setHoveredIcon] = useState(null);
    const [showVideoModal, setShowVideoModal] = useState(false);

    const handleMouseEnter = (iconName) => {
        setHoveredIcon(iconName);
    };

    const handleMouseLeave = () => {
        setHoveredIcon(null);
    };

    const handleVideoIconClick = () => {
        setShowVideoModal(true);
    };

    const handleCloseModal = () => {
        setShowVideoModal(false);
    };

    const styles = {
        stack: {
            padding: '.5rem',
            listStyle: 'none',
            textDecoration: 'none',
        },
        icon: {
            fontSize: '24px',
            padding: '.5rem',
            transition: 'color 0.3s ease-in-out'
        },
    };

    return (
        <Stack sx={styles.stack} direction="row" spacing={1}>
            {githubUrl && (
                <a
                    style={{
                        ...styles.icon,
                        color: hoveredIcon === 'github' ? color.colors.title : color.colors.secondaryText
                    }}
                    href={githubUrl}
                    target='_blank'
                    onMouseEnter={() => handleMouseEnter('github')}
                    onMouseLeave={handleMouseLeave}
                >
                    <GitHubIcon />
                </a>
            )}
            {videoUrl && (
                <a
                    style={{
                        ...styles.icon,
                        color: hoveredIcon === 'video' ? color.colors.title : color.colors.secondaryText
                    }}
                    onClick={handleVideoIconClick}
                    onMouseEnter={() => handleMouseEnter('video')}
                    onMouseLeave={handleMouseLeave}
                >
                    <i className="fas fa-video"></i>
                </a>
            )}
            <VideoModal open={showVideoModal} videoUrl={videoUrl} onClose={handleCloseModal} />
        </Stack>
    );
};

export default ProyectLink;
